import React from "react";
import { useNavigate } from "react-router-dom";

const BismillahSection = () => {
  const navigate = useNavigate();

  return (
    <div className='flex flex-col md:flex-row relative w-full max-w-screen-xl mx-auto my-40 h-max items-start p-5 md:p-10 gap-5'>
      <div className='flex flex-col order-2 md:order-1 justify-start w-full md:w-1/2 '>
        <h1 className='title-font mb-6 md:mb-14 text-6xl md:text-8xl'>
          Bismillah
        </h1>

        <p className='text-md md:text-xl mb-4'>
          The phrase "Bismillah" (بِسْمِ ٱللَّٰهِ), which means "In the name of
          Allah," is a significant and frequently used expression in Islam. It
          is the opening phrase of the Quran and is found at the beginning of
          all chapters (surahs) except for one (Surah At-Tawbah).
        </p>
        <p className='text-md md:text-xl mb-4'>
          The full form, "Bismillah ir-Rahman ir-Rahim" (بِسْمِ ٱللَّٰهِ
          ٱلرَّحْمَٰنِ ٱلرَّحِيمِ), translates to "In the name of Allah, the
          Most Gracious, the Most Merciful." This phrase underscores the
          importance of seeking Allah’s blessings and mercy before beginning any
          task.
        </p>

        <div className='flex flex-col w-full md:w-1/4 mt-10'>
          <button
            type='button'
            onClick={() => navigate("/blog/bismillah")}
            className={`
    
                bg-gray-900 text-white 
         border border-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800
          hover:bg-indigo-50 hover:border-indigo-800 hover:text-black
          `}
          >
            Learn more
          </button>
        </div>
      </div>
      <div className='flex flex-col w-full md:w-1/2 order-1 md:order-2 bg-slate-400'>
        <img src='/images/bismillah.jpg' className='w-full' alt='Bismillah' />
      </div>
    </div>
  );
};

export default BismillahSection;
