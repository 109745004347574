import React, { useState, useEffect } from "react";
import {
  HiOutlineArrowLongLeft,
  HiOutlineArrowLongRight,
} from "react-icons/hi2";

import { PageHeader } from "../components";
// import { doc, setDoc, getDoc } from "firebase/firestore";
// import { db } from "../modules/firebase";
import FIRST_TEN from "../utils/data/chapters/1:10.json";
import SECOND_TEN from "../utils/data/chapters/11:20.json";
import THIRD_TEN from "../utils/data/chapters/21:30.json";
import FOURTH_TEN from "../utils/data/chapters/31:40.json";
import FIFTH_TEN from "../utils/data/chapters/41:50.json";
import SIXTH_TEN from "../utils/data/chapters/51:60.json";
import SEVENTH_TEN from "../utils/data/chapters/61:70.json";
import EIGHTH_TEN from "../utils/data/chapters/71:80.json";
import NINETH_TEN from "../utils/data/chapters/81:90.json";
import TENTH_TEN from "../utils/data/chapters/91:100.json";
import ELENTH_TEN from "../utils/data/chapters/101:110.json";
import TWELFTH_TEN from "../utils/data/chapters/111:114.json";

const Translations = () => {
  const [suras, setSuras] = useState(null); // GEMETRA_DATA
  const [currSurasId, setCurrSurasId] = useState("1:10"); // GEMETRA_DATA
  const [selectedSura, setSelectedSura] = useState(null);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (!suras?.length) fetchSuras("1:10");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (suras?.length && !selectedSura) {
      setSelectedSura(suras[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSura]);

  // const uploadToFirebase = async (dataToUpload) => {
  //   console.log("Writing to Firebase");
  //   await dataToUpload.map((data) => {
  //   });
  // };

  // const writeDoc = async (data) => {
  //   const docRef = await setDoc(doc(db, "suras", data.sura.toString()), data);
  //   return docRef;
  // };

  // const fetchSingleSura = async (id) => {
  //   const docRef = await getDoc(doc(db, "suras", id));
  //   const data = docRef.data();
  //   return data;
  // };

  // write a function to fetch suras from db and use paginators
  // const fetchSuras = async (from, to) => {
  //   console.log("Function called");

  //   try {
  //     setIsFetchingData(true);
  //     const docRef = collection(db, "suras");
  //     const q = query(docRef, orderBy("sura","desc"), startAt(from), limit(10));
  //     // const q = query(docRef, startAt(from), limit(10));
  //     const querySnapshot = await getDocs(q);
  //     const data = querySnapshot.docs.map((d) => d.data());
  //     setSuras(data);
  //     setSelectedSura(data[0]);
  //     setIsFetchingData(false);
  //     return data;
  //   } catch (error) {
  //     console.error(error);
  //     setErrorMessage(error.message);
  //     setIsFetchingData(false);
  //   }
  // };

  const fetchSuras = async (id) => {
    setErrorMessage(null);
    setIsFetchingData(true);
    try {
      let selectSura;
      if (id === "1:10") {
        selectSura = FIRST_TEN;
      } else if (id === "11:20") {
        selectSura = SECOND_TEN;
      } else if (id === "21:30") {
        selectSura = THIRD_TEN;
      } else if (id === "31:40") {
        selectSura = FOURTH_TEN;
      } else if (id === "41:50") {
        selectSura = FIFTH_TEN;
      } else if (id === "51:60") {
        selectSura = SIXTH_TEN;
      } else if (id === "61:70") {
        selectSura = SEVENTH_TEN;
      } else if (id === "71:80") {
        selectSura = EIGHTH_TEN;
      } else if (id === "81:90") {
        selectSura = NINETH_TEN;
      } else if (id === "91:100") {
        selectSura = TENTH_TEN;
      } else if (id === "101:110") {
        selectSura = ELENTH_TEN;
      } else if (id === "111:114") {
        selectSura = TWELFTH_TEN;
      }

      if (selectSura) setCurrSurasId(id);
      setSuras(selectSura);
      setSelectedSura(selectSura[0]);

      setIsFetchingData(false);
    } catch (error) {
      console.error(error);
      setIsFetchingData(false);
      setErrorMessage(error.message);
    }
  };

  const paginateBack = () => {
    switch (currSurasId) {
      case "11:20":
        fetchSuras("1:10");
        break;
      case "21:30":
        fetchSuras("11:20");
        break;
      case "31:40":
        fetchSuras("21:30");
        break;
      case "41:50":
        fetchSuras("31:40");
        break;
      case "51:60":
        fetchSuras("41:50");
        break;
      case "61:70":
        fetchSuras("51:60");
        break;
      case "71:80":
        fetchSuras("61:70");
        break;
      case "81:90":
        fetchSuras("71:80");
        break;
      case "91:100":
        fetchSuras("81:90");
        break;
      case "101:110":
        fetchSuras("91:100");
        break;
      case "111:114":
        fetchSuras("101:110");
        break;
      default:
        fetchSuras("1:10");
        break;
    }
  };
  const paginateNext = () => {
    switch (currSurasId) {
      case "1:10":
        fetchSuras("11:20");
        break;
      case "11:20":
        fetchSuras("21:30");
        break;
      case "21:30":
        fetchSuras("31:40");
        break;
      case "31:40":
        fetchSuras("41:50");
        break;
      case "41:50":
        fetchSuras("51:60");
        break;
      case "51:60":
        fetchSuras("61:70");
        break;
      case "61:70":
        fetchSuras("71:80");
        break;
      case "71:80":
        fetchSuras("81:90");
        break;
      case "81:90":
        fetchSuras("91:100");
        break;
      case "91:100":
        fetchSuras("101:110");
        break;
      case "101:110":
        fetchSuras("111:114");
        break;
      default:
        fetchSuras("1:10");
        break;
    }
  };

  return (
    <>
      <div className='translation-section flex flex-col w-full h-max items-start p-10 '>
        <div className='flex flex-col w-full overflow-hidden'>
          <PageHeader
            showBack={true}
            title={"Translation"}
            subTitle={"Read your Quran in native language."}
          />
          <div className='flex flex-col w-full gap-5'>
            {isFetchingData ? (
              <>
                <div className='flex flex-col w-full gap-1 items-center'>
                  <h1 className='text-xl text-black'>Please wait...</h1>
                  <p className='text-base text-slate-500'>
                    Fetching data from the server.
                  </p>
                </div>
              </>
            ) : (
              <></>
            )}
            {errorMessage ? (
              <>
                <div className='flex flex-col w-full gap-1 items-center'>
                  <h1 className='text-xl text-red-800'>Error Message</h1>
                  <p className='text-base text-red-500'>{errorMessage}</p>
                </div>
              </>
            ) : (
              <></>
            )}
            <div className='flex flex-col w-full mx-auto max-w-full  md:max-w-screen-lg md:flex-row gap-5 md:gap-10 mb-80'>
              <div className='flex flex-col gap-5 min-w-[200px] w-full max-w-full md:max-w-[250px]'>
                <div className='flex flex-col w-full'>
                  <h3 className='text-lg font-bold'>Suras (144)</h3>

                  <div className='flex flex-row md:flex-col justify-between w-full gap-2.5'>
                    <small className='text-slate-500'>
                      Showing {currSurasId.split(":")[1] || "10"} of 144 suras
                    </small>
                    <div className='flex flex-row gap-5'>
                      <button
                        onClick={() => paginateBack()}
                        disabled={currSurasId === "1:10"}
                        className='flex justify-center items-center w-[50px] h-[25px] rounded-md border-2 border-black gap-2 cursor-pointer hover:text-indigo-500 hover:border-indigo-800'
                      >
                        <HiOutlineArrowLongLeft className='text-2xl' />
                      </button>
                      <button
                        onClick={() => paginateNext()}
                        disabled={currSurasId === "111:114"}
                        className='flex justify-center items-center w-[50px] h-[25px] rounded-md border-2 border-black gap-2 cursor-pointer hover:text-indigo-500 hover:border-indigo-800'
                      >
                        <HiOutlineArrowLongRight className='text-2xl' />
                      </button>
                    </div>
                  </div>
                </div>

                <div className='flex flex-row overflow-x-auto md:flex-col gap-2.5 w-full mb-50'>
                  <>
                    {suras?.length ? (
                      <>
                        {suras.map((sura) => (
                          <>
                            <div
                              key={sura}
                              className={`min-w-[150px]  cursor-pointer p-4 border rounded-lg shadow hover:border-2 hover:border-black hover:shadow-md dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 group
                                                              ${
                                                                Number(
                                                                  selectedSura.sura
                                                                ) ===
                                                                Number(
                                                                  sura.sura
                                                                )
                                                                  ? "bg-emerald-50 font-bold border-emerald-800 "
                                                                  : "bg-white border-gray-200 hover:bg-emerald-50"
                                                              }
                              `}
                              onClick={() => setSelectedSura(sura)}
                            >
                              <div className='flex flex-row w-full flex-wrap gap-2 items-end'>
                                <h1 className='text-2xl font-bold text-slate-700'>
                                  {sura.sura}
                                </h1>
                                <h5 className='mb-1 text-xl font-bold tracking-tight text-slate-500 dark:text-white'>
                                  {sura.arabic}
                                </h5>
                              </div>

                              <p className='font-normal text-gray-500 dark:text-gray-400'>
                                <small>{sura.title}</small>
                              </p>
                              <small className='text-xs text-slate-500 '>
                                {sura.verses} verses,{" "}
                              </small>
                            </div>
                          </>
                        ))}
                      </>
                    ) : (
                      <>
                        {[1, 2, 3].map((ind) => (
                          <>
                            <div
                              key={ind}
                              className='min-w-[150px] group gap-5 cursor-pointer blockw-full p-2.5 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700'
                            >
                              <div className='flex mb-2.5 w-[45%] h-[15px] rounded-full bg-slate-100 animate-pulse group-hover:bg-indigo-50'>
                                &nbsp;
                              </div>

                              <div className='flex mb-2.5 w-[75%] h-[15px] rounded-full bg-slate-100 animate-pulse group-hover:bg-indigo-50'>
                                &nbsp;
                              </div>
                              <div className='flex mb-2.5 w-[30%] h-[15px] rounded-full bg-slate-100 animate-pulse group-hover:bg-indigo-50'>
                                &nbsp;
                              </div>
                            </div>
                          </>
                        ))}
                      </>
                    )}
                  </>
                </div>
              </div>

              <div className='flex flex-col gap-5 w-full min-w-[50%] min-h-[1200px]'>
                {selectedSura ? (
                  <>
                    <div className='flex flex-row flex-wrap w-full items-center h-[50px]'>
                      <h3 className='text-lg font-bold'>
                        {selectedSura?.verses} Verses
                        {selectedSura?.name}
                      </h3>

                      <p className='text-base text-slate-500'>
                        &nbsp; - {selectedSura?.title || ""}
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <div className='flex flex-row flex-wrap w-full items-center'>
                      <h3 className='text-lg font-bold'>Verses</h3>
                    </div>
                  </>
                )}

                <div className='flex flex-col gap-5 p-4 w-auto min-w-md h-[1264px] overflow-y-auto mb-50'>
                  <>
                    {selectedSura?.chapters ? (
                      <>
                        {selectedSura.chapters.map((verse) => (
                          <>
                            <div
                              key={verse}
                              className='cursor-pointer block w-full p-2.5 px-8 bg-white border border-gray-200 rounded-lg shadow hover:bg-indigo-50 hover:border-2 hover:border-black hover:shadow-md dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700'
                            >
                              <div className='flex flex-row justify-between mb-5 md:mb-10 items-center'>
                                <h1 className='text-xl font-bold tracking-tight dark:text-white'>
                                  {verse.id}
                                </h1>

                                <div className='flex flex-col items-center text-xs text-slate-500 mt-2.5'>
                                  <span className=' text-indigo-500 font-bold text-4xl'>
                                    {verse.value.toLocaleString()}
                                  </span>
                                  <span className='text-gray-700'>
                                    Gemetra Value: &nbsp;
                                  </span>{" "}
                                </div>
                              </div>

                              <h5 className='mb-5 text-lg font-bold tracking-tight text-slate-500 dark:text-white'>
                                <span className='text-xl md:text-3xl'>
                                  {verse.arabic}
                                </span>
                              </h5>
                              <p className='mb-2.5 font-normal text-slate-500 dark:text-gray-400'>
                                {verse.eng}
                              </p>
                            </div>
                          </>
                        ))}
                      </>
                    ) : (
                      <>
                        {[1, 2, 3].map((ind) => (
                          <>
                            <div
                              key={ind}
                              className='group cursor-pointer block w-full p-2.5 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700'
                            >
                              <div className='flex mb-2.5 w-[45%] h-[15px] rounded-full bg-slate-100 animate-pulse group-hover:bg-indigo-50'>
                                &nbsp;
                              </div>
                              <div className='flex mb-2.5 w-[90%] h-[15px] rounded-full bg-slate-100 animate-pulse group-hover:bg-indigo-50'>
                                &nbsp;
                              </div>
                              <div className='flex mb-2.5 w-[75%] h-[15px] rounded-full bg-slate-100 animate-pulse group-hover:bg-indigo-50'>
                                &nbsp;
                              </div>
                              <div className='flex mb-2.5 w-[45%] h-[15px] rounded-full bg-slate-100 animate-pulse group-hover:bg-indigo-50'>
                                &nbsp;
                              </div>
                            </div>
                          </>
                        ))}
                      </>
                    )}
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Translations;
