import React, { lazy } from "react";
import Translations from "../pages/Translations";
import Treemap from "../pages/Treemap";
import WordCloud from "../pages/WordCloud";
const Home = lazy(() => import("../pages/Home"));
const About = lazy(() => import("../pages/About"));
const MathAnalysis = lazy(() => import("../pages/blogs/MathAnalysis"));
const MysteriousLetters = lazy(() =>
  import("../pages/blogs/MysteriousLetters")
);
const Bismillah = lazy(() => import("../pages/blogs/Bismillah"));
const NumberNineteen = lazy(() => import("../pages/blogs/NumberNineteen"));
const Graph = lazy(() => import("../pages/blogs/Graph"));
const Blog = lazy(() => import("../pages/blogs/Blog"));
const Contact = lazy(() => import("../pages/Contact"));
const Page404 = lazy(() => import("../pages/Page404"));

const routes = [
  {
    path: "/",
    exact: true,
    component: Home,
    name: "Home Page",
    protected: false,
    element: <Home />,
  },
  {
    path: "/about",
    exact: true,
    component: About,
    name: "About Us Page",
    protected: false,
    element: <About />,
  },
  {
    path: "/contact",
    exact: true,
    component: About,
    name: "Contact Page",
    protected: false,
    element: <Contact />,
  },
  {
    path: "/translations",
    exact: true,
    component: Translations,
    name: "Translations Page",
    protected: false,
    element: <Translations />,
  },
  {
    path: "/treemap",
    exact: true,
    component: Treemap,
    name: "Treemap Page",
    protected: false,
    element: <Treemap />,
  },
  {
    path: "/wordcloud",
    exact: true,
    component: WordCloud,
    name: "Word Cloud Page",
    protected: false,
    element: <WordCloud />,
  },
  {
    path: "/blog",
    exact: true,
    component: Blog,
    name: "Blog Page",
    protected: false,
    element: <Blog />,
  },
  {
    path: "/blog/mathematical-analysis",
    exact: true,
    component: MathAnalysis,
    name: "Mathematical Analysis Blog",
    protected: false,
    element: <MathAnalysis />,
  },
  {
    path: "/blog/mysterious-letters",
    exact: true,
    component: MysteriousLetters,
    name: "Mysterious Letters Blog",
    protected: false,
    element: <MysteriousLetters />,
  },
  {
    path: "/blog/bismillah",
    exact: true,
    component: Bismillah,
    name: "Bismillah Blog",
    protected: false,
    element: <Bismillah />,
  },
  {
    path: "/blog/number-ninenteen",
    exact: true,
    component: NumberNineteen,
    name: "Number Nineteen Blog",
    protected: false,
    element: <NumberNineteen />,
  },
  {
    path: "/blog/graph",
    exact: true,
    component: Graph,
    name: "Graph Blog",
    protected: false,
    element: <Graph />,
  },
  // {
  //   path: "/mathematical-analysis",
  //   exact: true,
  //   component: MathAnalysis,
  //   name: "mathematical Analysis Page",
  //   protected: false,
  //   element: <MathAnalysis />,
  // },
  {
    path: "*",
    exact: false,
    component: Page404,
    name: "404 Page",
    protected: false,
    element: <Page404 />,
  },
];

export default routes;
