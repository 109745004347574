import React, { useEffect } from "react";
import { ReactComponent as TreemapSvg } from "../assets/images/treemap.svg";

const GemetraTreemap = ({ data }) => {
  useEffect(() => {
    // renderTreemap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    // <div className='flex flex-col w-full overflow-scroll'>
    //   <svg ref={svgRef} />
    //   <svg ref={legendRef} />
    // </div>

    <div className='flex items-center w-full '>
      <div className='flex items-start w-full max-w-screen '>
        <TreemapSvg />
      </div>
      {/* <img src='/images/treemap.svg' className='w-full' alt='Quran Code' /> */}
    </div>
  );
};

export default GemetraTreemap;
