import React from "react";
import { useNavigate } from "react-router-dom";
import { HiOutlineArrowLongLeft } from "react-icons/hi2";

const PageHeader = ({ showBack, title, subTitle }) => {
    const navigate = useNavigate();

  return (
    <>
      <div className='flex flex-col md:flex-row justify-start mb-10 w-full gap-5 items-start '>
        {showBack ? (
          <div className='flex justify-end md:items-center w-full md:w-[50px] mt-2'>
            <button
              onClick={() => navigate(-1)}
              className='flex justify-center items-center w-[50px] h-[50px] rounded-full border-2 border-black gap-2 cursor-pointer hover:bg-indigo-50 hover:text-indigo-500 hover:border-indigo-800'
            >
              <HiOutlineArrowLongLeft className='text-2xl' />
            </button>
          </div>
        ) : (
          <></>
        )}

        <div className='flex flex-col gap-2.5 items-start '>
          <h1 className='title-font text-3xl md:text-6xl text-black'>
            {title}
          </h1>
          <p className='text-xl text-slate-700'>{subTitle} </p>
        </div>
      </div>
    </>
  );
};

export default PageHeader;
