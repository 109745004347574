import React from "react";
import ReactWordcloud from "react-wordcloud";

import { useNavigate } from "react-router-dom";

import { PageHeader } from "../components";
import WORD_CLOUD_DATA from "../utils/data/wordGemetraData.json";

const WordCloudPage = () => {
  const navigate = useNavigate();
  // const size = [600, 400];

  return (
    <>
      <div className='flex flex-col w-full h-max items-start  p-10'>
        <PageHeader
          showBack={true}
          title={"Word Cloud"}
          subTitle={"Quran representation as a word cloud structure."}
        />
        <div className='flex w-full min-h-[700px]'>
          <ReactWordcloud words={WORD_CLOUD_DATA} />
        </div>

        <div className='flex flex-col w-full md:w-1/4 mt-10'>
          <button
            type='button'
            onClick={() => navigate("/")}
            className={`bg-gray-900 text-white 
         border border-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800
          hover:bg-indigo-50 hover:border-indigo-800 hover:text-black
          `}
          >
            Go Back
          </button>
        </div>
      </div>
    </>
  );
};

export default WordCloudPage;
