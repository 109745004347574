import React from "react";
import { useNavigate } from "react-router-dom";

const NineteenSection = () => {
  const navigate = useNavigate();

  return (
    <div className='flex flex-col md:flex-row relative w-full max-w-screen-xl mx-auto my-40 h-max items-start p-5 md:p-10 gap-5 md:gap-10'>
      <div className='flex flex-col w-full max-w-[400px] md:w-1/2 order-1  bg-slate-400 rounded-xl'>
        <img
          src='/images/19.jpeg'
          className='w-full rounded-xl shadow-md'
          alt='Nineteen'
        />
      </div>
      <div className='flex flex-col order-2 md:order-1 justify-start w-full md:w-1/2 '>
        <h1 className='title-font mb-6 md:mb-14 text-6xl md:text-8xl'>
          Number 19
        </h1>

        <p className='text-md md:text-xl mb-4'>
          The Quran, the holy book of Islam, contains numerous instances of
          mathematical patterns, with the number 19 standing out prominently.
          This number is not only a mathematical curiosity but is also mentioned
          explicitly in the Quran as a proof of its divine authenticity.
        </p>
        <p className='text-md md:text-xl mb-4'>
          Chapter 74, known as "The Hidden Secret," introduces this 19-based
          mathematical code, describing it as “One of the Great miracles” in
          verse 35. Let's delve into the fascinating ways the number 19
          manifests in the Quran.
        </p>

        <div className='flex flex-col w-full md:w-1/4 mt-10'>
          <button
            type='button'
            onClick={() => navigate("/blog/number-ninenteen")}
            className={`
    
                bg-gray-900 text-white 
         border border-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800
          hover:bg-indigo-50 hover:border-indigo-800 hover:text-black
          `}
          >
            Learn more
          </button>
        </div>
      </div>
    </div>
  );
};

export default NineteenSection;
