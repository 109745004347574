import React, { useState } from "react";
const SubscribeSection = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would handle the form submission, e.g., send the data to your server
    setSuccessMessage("Thank you for subscribing!");
    setEmail("");
    setName("");
  };

  return (
    <div className='subscribe-section flex flex-col w-full  mx-auto my-40 h-max items-center p-5 md:p-10 gap-5 md:gap-10'>
      <div className='translucent-bg flex flex-col max-w-screen-xl mx-auto gap-10 py-20 md:py-40 px-16 my-40 justify-start w-full  rounded-3xl bg-gray-200'>
        <div className='flex flex-col w-auto min-w-[300px] items-center'>
          <h1 className='title-font mb-6 md:mb-14 text-6xl md:text-8xl'>
            Stay Up to date
          </h1>

          <p className='text-md md:text-xl mb-4 max-w-xl'>
            Join our community and stay updated with the latest insights, tips,
            and exclusive offers. As a subscriber, you will receive:
          </p>
        </div>

        <form
          onSubmit={handleSubmit}
          className='flex flex-col gap-10 max-w-lg min-w-[300px] mx-auto w-full'
        >
          <div className='flex flex-col w-full rounded-md shadow-sm gap-2.5'>
            {successMessage && (
              <p className='mt-4 text-center text-green-600'>
                {successMessage}
              </p>
            )}

            <div className='w-full'>
              <label htmlFor='name' className='sr-only'>
                Name
              </label>
              <input
                id='name'
                name='name'
                type='text'
                required
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  setSuccessMessage(null);
                }}
                className='appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm'
                placeholder='Name'
              />
            </div>
            <div>
              <label htmlFor='email-address' className='sr-only'>
                Email address
              </label>
              <input
                id='email-address'
                name='email'
                type='email'
                autoComplete='email'
                required
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setSuccessMessage(null);
                }}
                className='appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm'
                placeholder='Email address'
              />
            </div>
          </div>
          <div className='w-full'>
            <button
              type='submit'
              className={`w-full flex justify-center py-2 px-4 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
            >
              Subscribe{" "}
            </button>

            <div className='flex flex-col gap-5'>
              <ul className='mt-4 text-start text-gray-800 list-disc list-inside'>
                <li>Weekly insights on the Quran and its teachings</li>
                <li>Exclusive access to special content and resources</li>
                <li>Invitations to online events and webinars</li>
                <li>Updates on new features and tools on our website</li>
              </ul>
              <p className='text-md md:text-lg mb-4 text-zinc-500'>
                Be part of a community of like-minded individuals seeking to
                deepen their understanding and connection with the Quran.
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SubscribeSection;
