import React from "react";
import { useNavigate } from "react-router-dom";

const GraphSection = () => {
  const navigate = useNavigate();

  return (
    <div className='flex flex-col md:flex-row relative w-full max-w-screen-xl mx-auto my-40 h-max items-start p-5 md:p-10 gap-5'>
      <div className='flex flex-col order-2 md:order-1 justify-start w-full md:w-1/2 '>
        <h1 className='title-font mb-6 md:mb-14 text-6xl md:text-8xl'>
          Graph Visualisation
        </h1>

        <p className='text-md md:text-xl mb-4'>
          In our quest to understand the holy Quran better, we often look for
          new ways to explore its structure and content. Today, we're excited to
          share a unique visualization that helps us see the interconnectedness
          of Quranic chapters (surahs) in a new light.
        </p>

        <div className='flex flex-col w-full md:w-1/4 mt-10'>
          <button
            type='button'
            onClick={() => navigate("/blog/graph")}
            className={`
    
                bg-gray-900 text-white 
         border border-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800
          hover:bg-indigo-50 hover:border-indigo-800 hover:text-black
          `}
          >
            Learn more
          </button>
        </div>
      </div>
      <div className='flex flex-col w-full md:w-1/2 order-1 md:order-2 bg-slate-400'>
        <img
          src='/images/graph.png'
          className='w-full'
          alt='Graph Visualization'
        />
      </div>
    </div>
  );
};

export default GraphSection;
