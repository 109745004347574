import React from "react";

const Loading = ({ title, message }) => {
  return (
    <>
      <div className='flex flex-col w-full h-max items-start p-10 gap-2.5'>
        <h1 className='text-2xl text-black'>Loading ... </h1>
        <p className='text-base text-slate-500'>
          {message || "Please wait ..."}
        </p>
      </div>
    </>
  );
};

export default Loading;
