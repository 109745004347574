import React from "react";
const QuranTimeline = () => {
  return (
    <div className='flex flex-col relative w-full max-w-screen-xl mx-auto my-80 h-max items-start p-5 md:p-10 gap-5'>
      <h1 className='title-font mb-6 md:mb-14 text-6xl md:text-8xl'>
        History of the Quran
      </h1>

      <div class='flex flex-col justify-center divide-y divide-slate-200 [&>*]:py-16'>
        <div class='w-full max-w-3xl mx-auto'>
          <div class='relative pl-8 sm:pl-32 py-6 md:py-12 group'>
            <div class='title-font font-medium text-2xl md:text-4xl text-indigo-500 mb-1 md:mb-4'>
              The Revelation Begins
            </div>
            <div class='flex flex-col sm:flex-row items-start mb-1 md:mb-4 group-last:before:hidden before:absolute before:left-2 sm:before:left-0 before:h-full before:px-px before:bg-slate-300 sm:before:ml-[6.5rem] before:self-start before:-translate-x-1/2 before:translate-y-3 after:absolute after:left-2 sm:after:left-0 after:w-2 after:h-2 after:bg-indigo-600 after:border-4 after:box-content after:border-slate-50 after:rounded-full sm:after:ml-[6.5rem] after:-translate-x-1/2 after:translate-y-1.5'>
              <time class='sm:absolute left-0 translate-y-0.5 inline-flex items-center justify-center text-xs font-semibold uppercase w-20 h-6 mb-3 sm:mb-0 text-emerald-600 bg-emerald-100 rounded-full'>
                610 CE
              </time>
              <div class='text-xl md:text-2xl font-bold text-slate-900'>
                First revelation received by Muhammad in the cave of Hira
              </div>
            </div>
            <div class='text-slate-500 md:text-xl'>
              The Angel Gabriel delivers the first verses of the Quran to
              Muhammad, marking the beginning of the divine revelations.
            </div>
          </div>

          <div class='relative pl-8 sm:pl-32 py-6 md:py-12 group'>
            <div class='title-font font-medium text-2xl md:text-4xl text-indigo-500 mb-1 md:mb-4'>
              The Hijra
            </div>
            <div class='flex flex-col sm:flex-row items-start mb-1 md:mb-4 group-last:before:hidden before:absolute before:left-2 sm:before:left-0 before:h-full before:px-px before:bg-slate-300 sm:before:ml-[6.5rem] before:self-start before:-translate-x-1/2 before:translate-y-3 after:absolute after:left-2 sm:after:left-0 after:w-2 after:h-2 after:bg-indigo-600 after:border-4 after:box-content after:border-slate-50 after:rounded-full sm:after:ml-[6.5rem] after:-translate-x-1/2 after:translate-y-1.5'>
              <time class='sm:absolute left-0 translate-y-0.5 inline-flex items-center justify-center text-xs font-semibold uppercase w-20 h-6 mb-3 sm:mb-0 text-emerald-600 bg-emerald-100 rounded-full'>
                622 CE
              </time>
              <div class='text-xl md:text-2xl font-bold text-slate-900'>
                Migration from Mecca to Medina
              </div>
            </div>
            <div class='text-slate-500 md:text-xl'>
              Muhammad and his followers migrate to Medina, where the
              revelations continue to address new social and political
              circumstances.
            </div>
          </div>

          <div class='relative pl-8 sm:pl-32 py-6 md:py-12 group'>
            <div class='title-font font-medium text-2xl md:text-4xl text-indigo-500 mb-1 md:mb-4'>
              Compilation Begins
            </div>
            <div class='flex flex-col sm:flex-row items-start mb-1 md:mb-4 group-last:before:hidden before:absolute before:left-2 sm:before:left-0 before:h-full before:px-px before:bg-slate-300 sm:before:ml-[6.5rem] before:self-start before:-translate-x-1/2 before:translate-y-3 after:absolute after:left-2 sm:after:left-0 after:w-2 after:h-2 after:bg-indigo-600 after:border-4 after:box-content after:border-slate-50 after:rounded-full sm:after:ml-[6.5rem] after:-translate-x-1/2 after:translate-y-1.5'>
              <time class='sm:absolute left-0 translate-y-0.5 inline-flex items-center justify-center text-xs font-semibold uppercase w-20 h-6 mb-3 sm:mb-0 text-emerald-600 bg-emerald-100 rounded-full'>
                632 CE
              </time>
              <div class='text-xl md:text-2xl font-bold text-slate-900'>
                Compilation under Caliph Abu Bakr
              </div>
            </div>
            <div class='text-slate-500 md:text-xl'>
              After Muhammad's death, the first compilation of the Quran is
              undertaken to preserve its content.
            </div>
          </div>

          <div class='relative pl-8 sm:pl-32 py-6 md:py-12 group'>
            <div class='title-font font-medium text-2xl md:text-4xl text-indigo-500 mb-1 md:mb-4'>
              Standardization
            </div>
            <div class='flex flex-col sm:flex-row items-start mb-1 md:mb-4 group-last:before:hidden before:absolute before:left-2 sm:before:left-0 before:h-full before:px-px before:bg-slate-300 sm:before:ml-[6.5rem] before:self-start before:-translate-x-1/2 before:translate-y-3 after:absolute after:left-2 sm:after:left-0 after:w-2 after:h-2 after:bg-indigo-600 after:border-4 after:box-content after:border-slate-50 after:rounded-full sm:after:ml-[6.5rem] after:-translate-x-1/2 after:translate-y-1.5'>
              <time class='sm:absolute left-0 translate-y-0.5 inline-flex items-center justify-center text-xs font-semibold uppercase w-20 h-6 mb-3 sm:mb-0 text-emerald-600 bg-emerald-100 rounded-full'>
                644-656 CE
              </time>
              <div class='text-xl md:text-2xl font-bold text-slate-900'>
                Standardization under Caliph Uthman
              </div>
            </div>
            <div class='text-slate-500 md:text-xl'>
              Uthman ibn Affan commissions a standardized version of the Quran,
              distributing copies to various regions to ensure consistency.
            </div>
          </div>

          <div class='relative pl-8 sm:pl-32 py-6 md:py-12 group'>
            <div class='title-font font-medium text-2xl md:text-4xl text-indigo-500 mb-1 md:mb-4'>
              Preservation and Spread
            </div>
            <div class='flex flex-col sm:flex-row items-start mb-1 md:mb-4 group-last:before:hidden before:absolute before:left-2 sm:before:left-0 before:h-full before:px-px before:bg-slate-300 sm:before:ml-[6.5rem] before:self-start before:-translate-x-1/2 before:translate-y-3 after:absolute after:left-2 sm:after:left-0 after:w-2 after:h-2 after:bg-indigo-600 after:border-4 after:box-content after:border-slate-50 after:rounded-full sm:after:ml-[6.5rem] after:-translate-x-1/2 after:translate-y-1.5'>
              <time class='sm:absolute left-0 md:translate-y-[-1.5em] inline-flex items-center justify-center text-xs font-semibold uppercase w-max px-2 h-6 mb-3 sm:mb-0 text-emerald-600 bg-emerald-100 rounded-full'>
                8th-9th centuries CE
              </time>
              <div class='text-xl md:text-2xl font-bold text-slate-900'>
                Development of Diacritical Marks
              </div>
            </div>
            <div class='text-slate-500 md:text-xl'>
              Diacritical marks and vowel signs are developed to aid non-Arabic
              speakers in reading the Quran accurately.
            </div>
          </div>

          <div class='relative pl-8 sm:pl-32 py-6 md:py-12 group'>
            <div class='title-font font-medium text-2xl md:text-4xl text-indigo-500 mb-1 md:mb-4'>
              Global Reach
            </div>
            <div class='flex flex-col sm:flex-row items-start mb-1 md:mb-4 group-last:before:hidden before:absolute before:left-2 sm:before:left-0 before:h-full before:px-px before:bg-slate-300 sm:before:ml-[6.5rem] before:self-start before:-translate-x-1/2 before:translate-y-3 after:absolute after:left-2 sm:after:left-0 after:w-2 after:h-2 after:bg-indigo-600 after:border-4 after:box-content after:border-slate-50 after:rounded-full sm:after:ml-[6.5rem] after:-translate-x-1/2 after:translate-y-1.5'>
              <time class='sm:absolute left-0 md:translate-y-[-1.5em] inline-flex items-center justify-center text-xs font-semibold uppercase w-max px-2 h-6 mb-3 sm:mb-0 text-emerald-600 bg-emerald-100 rounded-full'>
                20th Century CE
              </time>
              <div class='text-xl md:text-2xl font-bold text-slate-900'>
                Translations and Modern Access
              </div>
            </div>
            <div class='text-slate-500 md:text-xl'>
              The Quran is translated into numerous languages, and advances in
              technology facilitate digital copies and online resources.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuranTimeline;
