import "./App.css";
import React, { Suspense } from "react";

import { routes } from "./modules";
import { Routes, Route } from "react-router-dom";
import { Navbar, Loading, Footer } from "./components";

function App() {
  return (
    <>
      <Suspense
        // fallback={<Loading title={'Loading...'} message={'Please wait.'} />}
        fallback={
          <div className='flex flex-col w-full max-h-full relative'>
            <Navbar />
            <div className='flex bg-white min-h-screen'> &nbsp;</div>
            <Loading
              title={"Page loading"}
              message={"Fetching data, please wait ..."}
            />
            <Footer />
          </div>
        }
      >
        <div className='flex flex-col box-border justify-between w-full h-max min-h-screen relative'>
          <Navbar />
          <div className='flex h-max w-full flex-col pt-[70px]'>
            <Routes>
              {routes.map((route, index) => (
                <>
                  <Route
                    key={`route-${index}`}
                    name={route.name}
                    path={route.path}
                    exact={route.exact}
                    element={route.element}
                  />
                </>
              ))}
            </Routes>
          </div>
          <Footer />
        </div>
      </Suspense>
    </>
  );
}

export default App;
