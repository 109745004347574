import React from "react";
const AboutQuran = () => {
  return (
    <div className='flex flex-col relative w-full max-w-screen-xl mx-auto my-80 h-max items-start p-5 md:p-10 gap-5'>
      <h1 className='title-font mb-6 md:mb-14 text-6xl md:text-8xl'>
        About The Quran
      </h1>

      <p className='text-md md:text-xl mb-4'>
        The Holly Quran, the holy book of Islam, was revealed to the{" "}
        <b>Prophet Muhammad</b> over a period of approximately 23 years,
        beginning in 610 CE. These revelations were delivered by the Angel
        Gabriel and later compiled into a single book. Written in classical
        Arabic, the Quran consists of <b>114 chapters</b>, known as surahs,
        which vary in length and cover a wide range of topics, including
        theology, law, morality, and guidance for personal conduct.
      </p>
      <p className='text-md md:text-xl mb-4'>
        Muslims believe the Quran to be the literal word of God, providing the
        foundation for Islamic beliefs and practices. It has been{" "}
        <b>preserved in its original language and remains unchanged</b> since
        its compilation. The Quran's profound impact extends beyond religious
        practices, influencing Islamic art, culture, and literature throughout
        history.
      </p>

      <p className='text-md md:text-xl mb-4'>
        For centuries, scholars and believers have studied the Quran to seek
        <b>
          spiritual guidance, understand divine teachings, and gain insight
        </b>{" "}
        into the essence of Islam. Today, the Quran continues to be a vital
        source of inspiration, guidance, and wisdom for millions of Muslims
        around the world.
      </p>

      <p className='text-md md:text-xl mb-4'>
        This website offers resources to deepen your understanding of the Holly
        Quran and its teachings.
      </p>
    </div>
  );
};

export default AboutQuran;
