import React from "react";
import { useNavigate } from "react-router-dom";

import { PageHeader, GemetraTreemap } from "../components";
import GEMETRA_DATA from "../utils/data/output.json";
const Treemap = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className='flex flex-col w-full h-max items-start  p-10'>
        <PageHeader
          showBack={true}
          title={"Treemap"}
          subTitle={"Quran representation as a tree structure."}
        />
        {/* <div className='flex w-full h-full overflow-scroll'></div> */}
        <GemetraTreemap data={GEMETRA_DATA} width={1920} height={1080} />

        <div className='flex flex-col w-full md:w-1/4 mt-10'>
          <button
            type='button'
            onClick={() => navigate("/")}
            className={`
    
                bg-gray-900 text-white 
         border border-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800
          hover:bg-indigo-50 hover:border-indigo-800 hover:text-black
          `}
          >
            Go Back
          </button>
        </div>
      </div>
    </>
  );
};

export default Treemap;
