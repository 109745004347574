import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const location = useLocation();

  const [links] = useState([
    { title: "Home", path: "/" },
    { title: "About", path: "/about" },
    { title: "Blog", path: "/blog" },
    { title: "Contact", path: "/contact" },
  ]);

  return (
    <nav className='fixed top-0 w-full z-10 translucent-bg'>
      <div className='flex flex-wrap items-center justify-between mx-auto p-2.5 md:p-5'>
        <Link to='/' className='flex flex-row gap-2 items-center'>
          <div className='flex items-center w-[20px] md:w-[30px]'>
            <img src='/logo.png' className='w-full' alt='Quran Code' />
          </div>
          <div className='flex items-center'>
            <span className='self-center text-xl md:text-2xl font-semibold whitespace-nowrap dark:text-white'>
              QuranCode
            </span>
          </div>
        </Link>

        <button
          data-collapse-toggle='navbar-default'
          type='button'
          className='inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600'
          aria-controls='navbar-default'
          aria-expanded='false'
        >
          <span className='sr-only'>Open main menu</span>
          <svg
            className='w-5 h-5'
            aria-hidden='true'
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 17 14'
          >
            <path
              stroke='currentColor'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='M1 1h15M1 7h15M1 13h15'
            />
          </svg>
        </button>
        <div className='hidden w-full md:block md:w-auto' id='navbar-default'>
          <ul className='font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-transparent dark:bg-gray-800 md:dark:bg-transparent dark:border-gray-700'>
            {links?.length &&
              links.map((link, ind) => (
                <li key={`nav-link-${ind}`}>
                  <Link
                    to={link.path}
                    className={`block py-2 px-3 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-indigo-700 md:p-0 dark:text-white md:dark:hover:text-indigo-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent
                      ${
                        link.path === location.pathname
                          ? "text-indigo-700"
                          : "text-gray-900 dark:text-gray-400"
                      }
                      `}
                  >
                    {link.title}
                  </Link>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </nav>
  );
};
export default Navbar;
